/*
    version 2.1.3 by charoenkeith.w@sea.com @ 19 sep 2023
    update for old component 
*/

import React from "react";
import styled from "styled-components";
import DOMPurify from 'isomorphic-dompurify';

export default class Footer extends React.Component{
  
    constructor(props) {
        super(props);

        this.state = {
            autoHide:       props.autoHide          || false,
            copyright:      props.copyright         || "",
            delayHide:      props.delayHide         || 3,
            fontFamily:     props.fontFamily        || {name: "", list: []},
            fontSize:       props.fontSize          || "1rem",
            imagesArrowUp:  props.imagesArrowUp     || "",
            logos:          props.logos             || [],
            logosMaxHeight: props.logosMaxHeight    || 50,
            maxWidth:       props.maxWidth          || "1700px",
            pp:             props.pp                || {title:"", link:""},
            tos:            props.tos               || {title:"", link:""},
            show:           true
        };
        
        this.regexGetFileType = new RegExp("(?!.*\\.)(\\w*)","g");
        this.tmo = null
        this.footerRef = React.createRef();
    }
    
    componentDidMount(){
        if(this.state.autoHide) {
            this.hideContent.bind(this)();
        }

       this.fetchData.bind(this)();
    }

    // function ================
    showContent(){
        if(!this.footerRef.current) return;
        if(this.tmo !== null) clearTimeout(this.tmo);
        this.setState({show: true})
    }
    hideContent(){
        if(!this.footerRef.current) return;
        if(!!this.tmol) clearTimeout(this.tmo);
        this.tmo = setTimeout(()=>{
            this.setState({show: false})
        },this.state.delayHide*1000);
    };
    generateFontfaceSrc(aryUrl) {
        let list = [];
        for(let i = 0; i< aryUrl.length; i++) {
          list.push("url("+aryUrl[i]+") format('"+aryUrl[i].match(this.regexGetFileType)[0]+"')")
        }
        return list.join(",");
    }
    async fetchData(){
        let data = {};
        let objUpdate = {...this.state};
        let url = "https://cdn-webth.garenanow.com/webth/cdn/fconline/footer.json";
        let option = { 
            headers: { 'Accept': 'application/json'} 
        };
        let res = await fetch(url,option);

        if(res.status === 200) data = await res.json();

        objUpdate = {...this.state, ...data, ...this.props};
        this.setState(objUpdate);
    }
    getLogosWith(ary, maxHeight=100){
        let sumWidth = 0;
        let widthList = new Array();
        let percentList = new Array();
        // for(let i = 0; i < ary.length; i++) {
        //     maxHeight = Math.max(maxHeight,ary[i].height);
        // }
        for(let j = 0; j < ary.length; j++) {
            let scale = maxHeight / ary[j].height;
            widthList[j] = ary[j].width * scale;
            sumWidth += widthList[j];
        }
        for(let k = 0; k < ary.length; k++) {
            percentList[k] = widthList[k] / sumWidth * 100;
        }
        return {
            width: widthList, 
            percent: percentList
        };
    }
    getLogosMaxHeight(logosMaxHeight) {
        const checkCSS = new RegExp(/\d+( cm|mm|Q|in|pc|pt|px|%|em|ex|rem|lh|rlh|vmin|vmax|vw|vh|ch|vb|vi|svw|svh|lvw|lvh|dvw|dvh )/);
        return checkCSS.test(logosMaxHeight) ?logosMaxHeight: "3em";
    }
    // function ================

    render(){

        if(!!this.props.inGame && !!this.props.inGame.status) return null;
        
        let propsData = {
            fontFamilyName: this.state.fontFamily.name,
            fontFamilyUrl: this.generateFontfaceSrc(this.state.fontFamily.list),
            
            imagesArrowUp: this.state.imagesArrowUp,
            fontSize: this.state.fontSize,   
            maxWidth: this.state.fontFamily.maxWidth,

            logosMaxHeight: this.getLogosMaxHeight(this.state.logosMaxHeight)
        }
    
        let setting = !this.state.autoHide? {} : {
            onMouseEnter: this.showContent.bind(this),
            onMouseLeave: this.hideContent.bind(this),
        }
        let logosWidth = this.getLogosWith(this.state.logos);

        return (
            <FooterLayout className={"footer " + (this.state.show ? 'show': 'hide')} {...setting} {...propsData} ref={this.footerRef}>
                <div className="footer__area">
                    <div className="footer__content">
                        <div className="footer__logos">
                            {this.state.logos.map((item, index) => 
                                <img 
                                    className="footer__image" 
                                    key={index} 
                                    src={item.url} 
                                    style={{
                                        // maxHeight: `calc(3em * ${item.scale})`
                                        // width: logosWidth.percent[index]+'%',
                                        // maxWidth: logosWidth.width[index]+'px'
                                        maxWidth: `min(${logosWidth.percent[index]}%,${logosWidth.width[index]}px)`
                                    }} 
                                />
                            )}
                        </div>
                        <div className="footer__inner">
                            <div className="footer__copyright" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.copyright)}} />
                        </div>
                        {(!!this.state.tos.title && !! this.state.pp.title) &&
                            <div className="footer__tospp">
                                <a href={this.state.tos.link} target="_blank" rel="noreferrer">{this.state.tos.title}</a>
                                <span className="footer__line">|</span>
                                <a href={this.state.pp.link} target="_blank" rel="noreferrer">{this.state.pp.title}</a>
                            </div>
                        }
                    </div>
                </div>
            </FooterLayout>
        );
    }
}

const FooterLayout = styled.div`

    /* variable */
    --menu-move: 0%;

    @font-face {
        font-family: ${props=>props.fontFamilyName};
        src: ${props=>props.fontFamilyUrl};
        font-weight: 300;
        font-style: normal;
    }
    font-size: ${props=>props.fontSize};
    font-family: ${props=>props.fontFamilyName};
    width: 100%;
    z-index: 400;
    position: relative;
    /* background: #0b0b0b; */
    overflow: hidden;
    pointer-events: none;
    &:after {
        z-index: 1;
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 3em;
        height: 3em;
        transform: translateX(-50%);
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(${props=>props.imagesArrowUp});
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
        pointer-events: all;
        
    }
    &.show{
        .footer{
            &__area{
                transform: translateY(0);
            }
        }
        &::after{
            opacity: 0;
        }
    }
    .footer {

        &__area {
            z-index: 2;
            position: relative;
            background-color: black;
            transform: translateY(100%);
            transition: transform 0.3s ease-in-out;
            pointer-events: all;
        }
        &__content {
            width: 89%;
            max-width: ${props=>props.maxWidth};
            color: #fff;
            padding: 0.5em 0;
            margin: 0 auto;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;
            text-align: center;
        }
        &__inner {
            display: block;
        }


        &__logos {
            width: 100%;
            height: ${props=>props.logosMaxHeight};
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__image {
            max-height: 100%;
        }
        
        &__copyright {
            text-align: center;
            text-transform: uppercase;
            line-height: 1em;
            margin: 0;
            font-size: 1em;
        }
        &__tospp {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1ch;
            font-size: 1em;
            * {
                text-transform: uppercase;
                color: #FFFFFF;
                text-decoration: none;
            }
            a {
                text-decoration: underline;
            }
        }
    }
`;