import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getCookie } from './src/cookie';
import { apiConsentInfo, apiConsentAccept } from './src/api';
import { connect } from "react-redux";
import { setValues } from '../../store/redux';
import SukhumvitSet_woff from './src/fonts/SukhumvitSet.woff';
import SukhumvitSet_ttf from './src/fonts/SukhumvitSet.ttf';
import SukhumvitSet_svg from './src/fonts/SukhumvitSet.svg';
import SukhumvitSet_otf from './src/fonts/SukhumvitSet.otf';
import SukhumvitSet_eot from './src/fonts/SukhumvitSet.eot';

function ConsentPopup(props) {

	let event_key = props.consent_event_key || "";
	let lang = props.consent_lang || "th";
	let token = props.consent_token || "";
	
	let first_token = getCookie(process.env.REACT_APP_CONSENT_COOKIE) || "";
	let second_token = getCookie(process.env.REACT_APP_CONSENT_SECOND_COOKIE) || "";

	let [state,updateState] = useState({
		accept: "LOADING",// PENDING , NO , YES , LOADING
		content: "...loading",
		event_name: "Event",
		game: "Garena",
		nickname: "nick name",
		title: "การขอความยินยอมใช้ข้อมูลส่วนบุคคล",
		jwt: ""
	});
	const setState = (object_valuse)=>updateState({...state,...object_valuse});

	//* functions
	const handleClick = (accept_consent) => {
		
		apiConsentAccept(
			state.jwt,
			accept_consent,
			event_key,
			(res)=>setState(res.data)
		);
	}
	const handleCallConsent = ()=>{
		let _param = token || first_token || second_token;
		if( !!lang && !!_param && !!event_key ) {
			apiConsentInfo(
				event_key,
				_param,
				lang,
				// success
				(res)=>{

					//! set values from api into redux;
					setState(res.data);
				},
				// error
				(res)=>{
					console.error(res);
				}
			);
		}
	}

	// * detect redux values
	useEffect(()=>{
		handleCallConsent( );
	},[props.consent_event_key,props.consent_lang,props.consent_token]);


	return (
		<ConsentStyle status={state.accept} >
			{state.accept === "PENDING" &&
				<div className="card">
					<div className="card__title">
						{state.title || "การขอความยินยอมใช้ข้อมูลส่วนบุคคล"}
					</div>
					<div className="card__game">
						{state.game}
					</div>
					<div className="card__event">
						{state.event_name}
					</div>
					<div 
						className="card__content" 
						dangerouslySetInnerHTML={{__html: state.content|| ""}} 
					/>
					<div className="card__buttons">
						<button className="btn btn--reject" onClick={()=>handleClick("NO")}>ไม่ยอมรับ	</button>
						<button className="btn btn--accept" onClick={()=>handleClick("YES")}>ยอมรับ</button>
					</div>
				</div>
			}
		</ConsentStyle>
	)
}

const mapStateToProps = state => ({
	consent_event_key: state.Main.consent_event_key,
	consent_lang: state.Main.consent_lang,
	consent_token: state.Main.consent_token,
});
const mapDispatchToProps = { setValues };
export default connect( mapStateToProps, mapDispatchToProps )(ConsentPopup);

const ConsentStyle = styled.div`

	@font-face {
		font-family: SukhumvitSet;
		src: url(${SukhumvitSet_woff}) format("woff"),
				 url(${SukhumvitSet_ttf}) format("ttf"),
				 url(${SukhumvitSet_svg}) format("svg"),
				 url(${SukhumvitSet_otf}) format("otf"),
				 url(${SukhumvitSet_eot}) format("embedded-opentype")
	}
	font-size: 14px;
	text-size-adjust: none;
	font-family: SukhumvitSet, thahoma;
	color: #252525;
	z-index: 9500;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: #00000066;
	transition: opacity 0.6s ease-in-out;
	opacity: ${props=>(props.status==="PENDING")? "1":"0"};
	user-select: ${props=>(props.status==="PENDING")? "all":"none"};
	pointer-events: ${props=>(props.status==="PENDING")? "all":"none"};
	
	.card {
		position: relative;
		background-color: #ffffff;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-direction: column;
		width: 80%;
		height: 80%;
		max-width: 800px;
		max-height: 400px;
		border-radius: 5px;
		padding: 1em;
		/* transition: all 0.6s cubic-bezier(0,1.8,.7,.7); */
		transition: all 0.6s ease-in-out;
		opacity: ${props=>(props.status==="PENDING")? "1":"0"};
		transform: scale(${props=>(props.status=="PENDING")? "1":"0.5"});
		&__title {
			padding: 0.4em 0px;
			margin: 0;
			// min-height: calc(1em + 1rem);
			font-size: 2em;
			// font-size: min(2em, 6vmin);
			text-align: center;
    	line-height: 1em; 
		}
		&__game {
			text-align: center;
			font-size: 1em;
			// font-size: min(1rem,4vmin);
			color: #676767;
			line-height: 1.3em;
		}
		&__event {	
			text-align: center;
			font-size: 0.8em;
			// font-size: min(0.8rem,4vmin);
			color: #969393;

			line-height: 1.3em;
		}
		&__content {
			flex: 1;
			font-size: 1em;
			background-color: #dddddd;
			overflow-y: auto;
			overflow-x: hidden;
			border-radius: 5px;
			padding: 0px 2ch;
			margin: 1em 0px;
			// padding: clamp(10px, 2vmin, 2ch); 
			&::-webkit-scrollbar {
				width: 10px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: #dddddd;
				&:hover {
					background: #cccccc;
				}
			}
			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #aaaaaa;
				border-radius: 3px;
				&:hover {
					background: #666666;
				}
			}
		}
		&__buttons {
			display: flex;
			// height: calc(2em + 1rem);
			justify-content: space-between;
			align-items: flex-end;
			flex-direction: row;
			width: 100%;
			max-width: 240px;
			margin: 0 auto;
		}
	}
	.btn {
		font-size: 1em;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 2em;
		width: 100px;
		border-radius: 3px;

		&--reject {
			color: #ff0000;
			background: #ffffff;
			border: 1px solid #ff0000;
		}
		&--accept {
			color: #ffffff;
			background: #ff0000;
			border: none;
		}
	}
`;
