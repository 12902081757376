import React from 'react';
import styled from 'styled-components';
import  ModalCore from './ModalCore.js';
import { connect } from "react-redux";
import {setValues} from './redux';
import { imgList } from './../../constants/ImagesList';

const HistoryModal = (props) => {
	return (
		<ModalCore name="history" outSideClick={true}>
			<ModalContent>
                <Content>
                    <div className="history__row">
                        <div>วันเวลา</div>
                        <div>รายการของรางวัล</div>
                        <div>สถานะ</div>
                    </div>
                    {props.modal_history && props.modal_history.length > 0 && props.modal_history.map((item, key) => {
                        return (
                            <div className="history__row history__row--detail" key={"history_"+key}>
                                <div>{item.time}</div>
                                <div>{item.name}</div>
                                <div>{item.status}</div>
                            </div>
                        );
                    })}
                    {props.modal_history_canload && 
                        <div className="history__btn" onClick={()=>props.apiLoadmoreHistory(props.modal_history_page+1)}>
                            <img src={imgList.btn_loadmore} alt=''/>
                        </div>
                    }
                </Content>
				<BtnClose onClick={()=>props.setValues({modal_open: ''})}>
					<img src={imgList.btn_close} alt=''/>
				</BtnClose>
			</ModalContent>
		</ModalCore>
	)
}

const mapStateToProps = state => ({
	...state.Main,
    ...state.Modal
});

const mapDispatchToProps = {setValues};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoryModal);

const ModalContent = styled.div`
	position: relative;
	z-index: 20;
	pointer-events: visible;
	width: calc(70vmin * (1881/1097));
    height: 70vmin;
	background: url(${imgList.popup_history}) no-repeat center top;
	background-size: cover;
`;
const Content = styled.div`
    position: absolute;
    width: 75%;
    height: 58%;
    left: 13.5%;
    bottom: 23%;
    overflow: auto;
    ::-webkit-scrollbar {
        width: .7vw;
    }
    ::-webkit-scrollbar-track {
        background: #3e3e3e;
        border-radius: 2px;
    }
    ::-webkit-scrollbar-thumb {
        background: #a4a3a4;
        border-radius: 2px;
    }
	.history{
        &__row{
            width: 75%;
            margin: 0 auto 2%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            text-align: center;
            font-size: 4vmin;
            color: #edaa8d;
            >div{
                width: 40%;
                &:nth-child(1){
                    width: 32%;
                }
                &:nth-child(3){
                    width: 22%;
                }
            }
            &--detail{
                margin: 0 auto 1%;
                color: #fff;
            }
        }
        &__btn{
            width: 15%;
            margin: 1% auto;
            cursor: pointer;
            transition: opacity .2s ease-in-out;
            &:hover{
                opacity: .8;
            }
            >img{
                width: 100%;
                display: block;
            }
        }
    }
`;
const BtnClose = styled.div`
    position: absolute;
    top: -10.5vmin;
    right: 7vmin;
    width: 10vmin;
    cursor: pointer;
    transition: opacity .2s ease-in-out;
    &:hover{
        opacity: .8;
    }
    >img{
        width: 100%;
        display: block;
    }
`;
