import React from 'react';
import styled from 'styled-components';
import  ModalCore from './ModalCore.js';
import { connect } from "react-redux";
import {setValues} from './redux';
import { imgList } from './../../constants/ImagesList';

const MessageModal = (props) => {
	return (
		<ModalCore name="message" outSideClick={true}>
			<MessageModalContent>
				<Content>
					<div dangerouslySetInnerHTML={{__html: props.modal_message}}/>
				</Content>
				<BtnClose onClick={()=>props.setValues({modal_open: ''})}>
					<img src={imgList.btn_close} alt=''/>
				</BtnClose>
			</MessageModalContent>
		</ModalCore>
	)
}

const mapStateToProps = state => ({
	...state.Main,
    ...state.Modal
});

const mapDispatchToProps = {setValues};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MessageModal);

const MessageModalContent = styled.div`
	position: relative;
	z-index: 20;
	pointer-events: visible;
	width: calc(45vmin * (768/571));
	height: 45vmin;
	background: url(${imgList.bg_message}) no-repeat center top;
	background-size: cover;
	padding-top: 8%;
`;
const Title = styled.div`
	width: 100%;
	margin: 0 auto;
	height: 20%;
	line-height: 1;
	display: flex;
    align-items: center;
    justify-content: center;
    font-size: 7vmin;
	color: #fff;
`;
const Content = styled.div`
	display: flex;
    align-items: center;
    justify-content: center;
	flex-direction: column;
	width: 95%;
    height: 75%;
    margin: 0 auto;
	>div{
		color: #fff;
		font-size: 6vmin;
		text-align: center;
	}
`;
const BtnClose = styled.div`
    position: absolute;
    top: -6.5vmin;
    right: -.5vmin;
    width: 14vmin;
    cursor: pointer;
    transition: opacity .2s ease-in-out;
    &:hover{
        opacity: .8;
    }
    >img{
        width: 100%;
        display: block;
    }
`;
