import React from 'react';

export default (props) => {
    return (
        <div className="videobgcontent">
            {props.detail && props.detail.length > 0 && props.detail.map((item, key) => {
                return (
                    <video loop muted autoPlay poster={item.poster || ""} className="videobg" key={key}>
                        {item.webm && <source src={item.webm} type="video/webm" />}
                        {item.mp41  && <source src={item.mp4}  type="video/mp4"  />}
                        {item.ogg1  && <source src={item.ogg}  type="video/ogg"  />}
                    </video>
                );
            })}
        </div>

    )
}
