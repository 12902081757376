import React from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';
import { setValues } from '../store/redux';
import { setValues as setModalValues } from '../features/modal/redux';

const controler = (props) => {
    return (
        <Boxcontroler>
            <img src={imgList.controler} alt='' />
            <div className="btncontroler">
                {
                    props.select_top!==-1 && props.select_upgrade!==-1 && props.selected.grade===0
                    ? 
                        <div className="btncontroler__wrap" onClick={() => props.setModalValues({modal_open:'confirmchoose'})}>
                            <img src={imgList.controler_btn_confirm} alt=''/>
                            <img src={imgList.controler_btn_confirm_hover} alt=''/>
                        </div>
                    :
                        <img src={imgList.controler_btn_confirmed} alt=''/>
                }
            </div>
            {props.selected.grade===0
            ?
                <>
                    <div className="btncontroler__number btncontroler__number--1">{props.selected.match_daily}/10</div>
                    <div className="btncontroler__number btncontroler__number--2">0/{props.showChoose&&props.showChoose.length>0?props.showChoose[0].match:0}</div>
                </>
            :
                <>
                    <div className="btncontroler__number btncontroler__number--1">{props.selected.match_daily}/10</div>
                    <div className="btncontroler__number btncontroler__number--2">{props.selected.match}/{props.selected.match_max}</div>
                </>
            }
        </Boxcontroler>
    )
}

const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});
const mapDispatchToProps = { setValues, setModalValues };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(controler);

const Boxcontroler = styled.div`
    position: absolute;
    bottom: 0;
    right: 11%;
    width: 12%;
    >img {
        width: 100%;
        display: block;
    }
    .btncontroler {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 72%;
        margin: 0 auto;
        &__wrap{
            position: relative;
            cursor: pointer;
            >img{
                width: 100%;
                display: block;
                &:nth-child(2){
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            &:hover{
                >img{
                    &:nth-child(1){
                        opacity: 0;
                    }
                    &:nth-child(2){
                        opacity: 1;
                    }
                }
            }
        }
        &__number{
            text-align: center;
            font-size: 2vw;
            color: #DBA89A;
            &--1{
                position: absolute;
                width: 100%;
                top: 51.5%;
                transform: translate(0, -50%);
            }
            &--2{
                position: absolute;
                width: 100%;
                top: 84%;
                transform: translate(0, -50%);
            }
        }
    }
`;