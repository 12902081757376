import React,{useState,useEffect} from 'react';
import styled,{keyframes} from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';
import Card from '../common/Card';
import { setValues } from '../store/redux';
import { setValues as setModalValues } from '../features/modal/redux';
import { slotReward, classReward, topReward, updateReward, cardReward, cardTopReward, cardUpgradeReward } from './../constants/Reward';
import {getCookie} from './../constants/Cookie';
import _ from 'lodash';

const Machine = props => {
    const [canTop, setCanTop] = useState([]);
    const [canUpgrade, setCanUpgrade] = useState([]);
    const handleChangeClass = (index) =>{
        props.setValues({
            select_class: index,
            select_top: -1,
            select_upgrade: -1,
            showChoose: []
        })
        if(props.group){
            setCanTop(_.filter(props.group, { 'class_id': index+1}).map(item=>item.top_id))
        }
    }
    const handleChangeTop = (index) =>{
        props.setValues({
            select_top: index,
            select_upgrade: -1,
            showChoose: []
        })
        if (props.group) {
            setCanUpgrade(_.filter(props.group, { 'class_id': props.select_class+1,'top_id': index+1}).map(item=>item.grade_id))
        }
        if(props.select_upgrade!==-1){
            let a = _.filter(props.group, { 'class_id': props.select_class+1,'grade_id': props.select_upgrade+1,'top_id': index+1});
            props.setValues({showChoose: a})
        }
    }
    const handleChangeGrade = (index) =>{
        props.setValues({select_upgrade: index})
        if(props.select_top!==-1){
            let a = _.filter(props.group, { 'class_id': props.select_class+1,'grade_id': index+1,'top_id': props.select_top+1});
            props.setValues({showChoose: a})
        }
    }
    useEffect(() => {
        if(props.selected.class!==0 && props.selected.top!==0 && props.selected.grade!==0){
            setCanTop(_.filter(props.group, { 'class_id': props.selected.class}).map(item=>item.top_id))
            setCanUpgrade(_.filter(props.group, { 'class_id': props.selected.class,'top_id': props.selected.top}).map(item=>item.grade_id))
            props.setValues({
                select_class: props.selected.class-1,
                select_top: props.selected.top-1,
                select_upgrade: props.selected.grade-1,
            })
            setTimeout(() => {
                props.setValues({
                    loading: false
                })
            }, 100);
        }
        else{
            setCanTop(_.filter(props.group, { 'class_id': props.selected.class+1}).map(item=>item.top_id))
        }
    }, [props.group,props.selected]);
    return (
        <Content className="machine">
            <img src={imgList.machine} alt=''/>
            <div className="machine__group">
                <div className={"machine__wrapselect" + (!props.api?" close":"")}>
                    <div>
                        <img src={props.select_class == -1 ? imgList.select_class : imgList.select_class_active} alt=''/>
                    </div>
                    <div className="machine__wrapitem">
                        {
                            classReward.map((reward, index) => {
                                if(props.selected.class!==0){
                                    return (
                                        <div key={index} className={`machine__btnselect default ${props.select_class == index && "active"}`}>
                                            <img src={reward.image} alt=''/>
                                        </div>
                                    );
                                }
                                else{
                                    return (
                                        <div key={index} className={`machine__btnselect ${props.select_class == index && "active"}`} onClick={() => handleChangeClass(index)}>
                                            <img src={reward.image} alt=''/>
                                        </div>
                                    );
                                }
                            })
                        }
                    </div>
                </div>
                <div className={"machine__wrapselect" + (!props.api?" close":"")}>
                    <div>
                        <img src={props.select_top == -1 ? imgList.select_top : imgList.select_top_active} alt=''/>
                    </div>
                    <div className="machine__wrapitem">
                        {
                            topReward.map((reward, index) => {
                                let active = (canTop.indexOf(index+1)<0)? false: true;
                                let noclick = props.selected.top!==0?true:false;
                                if(!active){
                                    return (
                                        <div key={index} className={`machine__btnselect ${props.select_top == index && "active"} ${noclick && "default"} noclick`}>
                                            <img src={reward.image} alt=''/>
                                        </div>
                                    );
                                }
                                else{
                                    return (
                                        <div key={index} className={`machine__btnselect ${props.select_top == index && "active"} ${noclick && "default"}`} onClick={() => handleChangeTop(index)}>
                                            <img src={reward.image} alt=''/>
                                        </div>
                                    );
                                }
                            })
                        }
                    </div>
                </div>
                <div className={"machine__wrapselect" + (!props.api?" close":"")}> 
                    <div>
                        <img src={props.select_upgrade == -1 ? imgList.select_upgrade : imgList.select_upgrade_active} alt=''/>
                    </div>
                    <div className="machine__wrapitem">
                        {
                            updateReward.map((reward, index) => {
                                let active = (canUpgrade.indexOf(index+1)<0)? false: true;
                                let noclick = props.selected.grade!==0?true:false;
                                if (!active) {
                                    return (
                                        <div key={index} className={`machine__btnselect ${props.select_upgrade == index && "active"} ${noclick && "default"} noclick`}>
                                            <img src={reward.image} alt=''/>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={index} className={`machine__btnselect ${props.select_upgrade == index && "active"} ${noclick && "default"}`} onClick={() => handleChangeGrade(index)}>
                                            <img src={reward.image} alt=''/>
                                        </div>
                                    );
                                }
                                
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="machine__box">
                {
                    props.select_class !== -1
                    &&
                    <Card
                        home={true}
                        sclass={props.select_class}
                        supdate={props.select_upgrade}
                        stop={props.select_top}
                    />
                }
                <img src={imgList.box} alt=''/>
                {props.selected.status &&
                    <img src={imgList.btn_receive} alt='' className="machine__receive" onClick={()=>props.apiClaim()}/>
                }
            </div>
        </Content>
    )
}
const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});
const mapDispatchToProps = { setValues, setModalValues };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Machine);

const Content = styled.div`
    position: relative;
    width: 100%;
    >img{
        width: 100%;
        display: block;
        opacity: 0;
        @media only screen and (max-width: 1024px) {
            opacity: 1;
        }
    }
    .machine{
        &__group{
            position: absolute;
            bottom: 0;
            left: 18.5%;
            width: 40.6%;
            height: 55%;
            display: grid;
            grid-template-columns: auto auto auto;
        }
        &__wrapselect{
            padding: 2%;
            img{
                width: 100%;
                display: block;
            }
            &.close{
                pointer-events: none;
            }
        }
        &__wrapitem{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 88.5%;
        }
        &__btnselect{
            display: block;
            padding: 6% 0%;
            margin: 0 4%;
            cursor: pointer;
            img{
                width: 62%;
                margin: 0 auto;
            }
            &.active{
                background: rgb(133, 244, 95);
            }
            &.noclick{
                filter: brightness(0.5);
                cursor: default;
                pointer-events: none;
            }
            &.default{
                cursor: default;
            }
        }
        &__card{
            width: 80%;
            margin: 0 auto;
            position: relative;
        }
        &__cardupgrade{
            width: 25%;
            position: absolute;
            top: 30%;
            right: 5%;
        }
        &__cardtop{
            position: absolute;
            right: 0;
            bottom: 3%;
            width: 70%;
        }
        &__box{
            position: absolute;
            right: 1.5%;
            width: 25%;
            bottom: 22%;
            >img{
                opacity: 0;
                display: block;
                @media only screen and (max-width: 1024px) {
                    opacity: 1;
                }
                &:nth-child(2){
                    width: 87%;
                    margin-top: 4%;
                }
            }
        }
        &__receive{
            opacity: 1 !important;
            position: absolute;
            opacity: 1 !important;
            width: 52.3%;
            bottom: 2.5%;
            left: 11%;
            cursor: pointer;
            transition: opacity .2s ease-in-out;
            &:hover{
                opacity: .8;
            }
        }
    }
`;