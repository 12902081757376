import React from 'react';
import styled,{keyframes} from 'styled-components';
import  ModalCore from './ModalCore.js';
import { connect } from "react-redux";
import {setValues} from './redux';

const LoadingModal = (props) => {
	return (
		<ModalCore name="loading" outSideClick={false}>
			<Loadingwrap color={'#f7f64b'}>
				<div className="loading">
					<div className="loadingring"/>
				</div>
			</Loadingwrap>
		</ModalCore>
	)
}

const mapStateToProps = state => ({
    ...state.Modal
});

const mapDispatchToProps = { setValues};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoadingModal);

const LoadingAnimation = keyframes`
	 0% {
	    transform: rotate(0deg);
	  }
	  100% {
	    transform: rotate(360deg);
	  }
`
const Loadingwrap = styled.div`
		.loading{
			text-align: center;
			height: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 auto;
		}
		.loadingring {
			display: flex;
			justify-content: center;
			align-items: center;
			@media(min-width: 1024px){
				transform: scale(0.5);
			}
		}
		.loadingring:after {
			content: " ";
			display: block;
			width: 10vw;
			height: 10vw;
			margin: 1px;
			border-radius: 50%;
			animation: ${LoadingAnimation} 1.2s linear infinite;
			filter: drop-shadow(0 0 1px #000000) drop-shadow(0 0 2px #000000);
			${props=> props.color &&
			`
				border: 0.7vw solid ${props.color};
				border-color: ${props.color} transparent ${props.color} transparent;
			`
			}
		}
`