import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import  ModalCore from './ModalCore.js';
import Card from '../../common/Card';
import { connect } from "react-redux";
import {setValues} from './redux';
import { imgList } from './../../constants/ImagesList';
import _ from 'lodash';

const ConfirmChooseModal = (props) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if(props.select_upgrade!==-1 && props.select_top!==-1){
            let select_top = props.select_top+1;
            let select_upgrade = props.select_upgrade+1;
            let a = _.filter(props.group, { 'class_id': props.select_class+1,'grade_id': select_upgrade,'top_id': select_top});
            setData(a);
        }
    }, [props.select_class,props.select_upgrade,props.select_top]);
	return (
		<ModalCore name="confirmchoose" outSideClick={true}>
			<ModalContent team={props.modal_team}>
                <div className="confirmchoose">
                    <div className="confirmchoose__content">
                        <div className="confirmchoose__card">
                            <Card
                                sclass={props.select_class}
                                supdate={props.select_upgrade}
                                stop={props.select_top}
                            />
                        </div>
                        <div className="confirmchoose__name">{data[0] && data[0].product_name}</div>
                        <div>จำนวนแมตซ์สะสม {data[0] && data[0].match} แมตซ์</div>
                    </div>
                    <BtnGroup>
                        <img src={imgList.btn_accept} alt='' onClick={()=>props.apiSelectItem()}/>
                        <img src={imgList.btn_cancel} alt='' onClick={()=>props.setValues({modal_open: ''})}/>
                    </BtnGroup>
                </div>
			</ModalContent>
		</ModalCore>
	)
}

const mapStateToProps = state => ({
	...state.Main,
    ...state.Modal
});

const mapDispatchToProps = {setValues};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmChooseModal);

const ModalContent = styled.div`
	position: relative;
	z-index: 20;
    pointer-events: visible;
	width: calc(80vmin * (911/713));
	height: 80vmin;
	background: url(${imgList.popup_confirm}) no-repeat center top;
    background-size: cover;
    .confirmchoose{
        position: absolute;
        bottom: 8%;
        left: 0;
        right: 0;
        width: 80%;
        margin: 0 auto;
        &__content{
            text-align: center;
            margin: 2% auto;
            font-size: 2.2vw;
        }
        &__card{
            width: 30%;
            margin: 0 auto;
        }
        &__name{
            color: #DBA89A;
        }
    }
`;
const BtnGroup = styled.div`
    width: 45%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    >img{
        width: 35%;
        margin: 0 10%;
        display: block;
        cursor: pointer;
        transition: opacity .2s ease-in-out;
        &:hover{
            opacity: .8;
        }
    }
`;
