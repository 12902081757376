const cdn_path = 'https://cdn-webth.garenanow.com/webth/cdn/fconline/packfactory-v3';

const class_hot = cdn_path + '/class_hot.png';
const class_19ucl = cdn_path + '/class_19ucl.png';
const class_coc = cdn_path + '/class_coc.png';
const class_ng = cdn_path + '/class_ng.png';
const class_otw = cdn_path + '/class_otw.png';
const class_tc = cdn_path + '/class_tc.png';
const class_gr = cdn_path + '/class_gr.png';
const class_tt = cdn_path + '/class_tt.png';

const top_150 = cdn_path + '/top_150.png';
const top_100 = cdn_path + '/top_100.png';
const top_50 = cdn_path + '/top_50.png';

const upgrade_1 = cdn_path + '/upgrade_1.png';
const upgrade_3 = cdn_path + '/upgrade_3.png';
const upgrade_5 = cdn_path + '/upgrade_5.png';

const card_1 = cdn_path + '/cards/1.png';
const card_2 = cdn_path + '/cards/2.png';
const card_3 = cdn_path + '/cards/3.png';
const card_4 = cdn_path + '/cards/4.png';
const card_5 = cdn_path + '/cards/5.png';
const card_6 = cdn_path + '/cards/6.png';
const card_7 = cdn_path + '/cards/7.png';
const card_8 = cdn_path + '/cards/8.png';

const card_top_50 = cdn_path + '/s_top_50.png';
const card_top_100 = cdn_path + '/s_top_100.png';
const card_top_150 = cdn_path + '/s_top_150.png';

const card_upgrade_1 = cdn_path + '/s_upgrade_1.png';
const card_upgrade_3 = cdn_path + '/s_upgrade_3.png';
const card_upgrade_5 = cdn_path + '/s_upgrade_5.png';

export const classReward = [
    {key:'class_1', image: class_hot},
    {key:'class_2', image: class_19ucl},
    {key:'class_3', image: class_coc},
    {key:'class_4', image: class_ng},
    {key:'class_5', image: class_otw},
    {key:'class_6', image: class_tc},
    {key:'class_7', image: class_gr},
    {key:'class_8', image: class_tt}
];

export const topReward = [
    {key:'top_1', image: top_150},
    {key:'top_2', image: top_100},
    {key:'top_3', image: top_50}
];

export const updateReward = [
    {key:'update_1', image: upgrade_1},
    {key:'update_2', image: upgrade_3},
    {key:'update_3', image: upgrade_5}
];

export const cardReward = [
    {key:'card_1', image: card_1},
    {key:'card_2', image: card_2},
    {key:'card_3', image: card_3},
    {key:'card_4', image: card_4},
    {key:'card_5', image: card_5},
    {key:'card_6', image: card_6},
    {key:'card_7', image: card_7},
    {key:'card_8', image: card_8}
];

export const cardTopReward = [
    {key:'card_top_150', image: card_top_150},
    {key:'card_top_100', image: card_top_100},
    {key:'card_top_50', image: card_top_50},
];

export const cardUpgradeReward = [
    {key:'card_upgrade_1', image: card_upgrade_1},
    {key:'card_upgrade_3', image: card_upgrade_3},
    {key:'card_upgrade_5', image: card_upgrade_5},
];