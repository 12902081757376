import React from 'react'
import styled,{keyframes} from 'styled-components';
import {setValues , actCloseModal} from './redux';
import { connect } from "react-redux";

const ModalCore =(props)=>{
	let {modal_open} = props;
	return(
		<Modal className={modal_open===props.name ? "open":"close"} >
			<ModalBackdrop
				onClick={()=>{if(props.outSideClick)props.actCloseModal()}}
			/>
			<div className="modalContent">
				{props.children && props.children}
			</div>
		</Modal>
	)
}

const mapStateToProps = state => ({
    ...state.Modal
});

const mapDispatchToProps = { setValues ,actCloseModal};

export default connect( 
    mapStateToProps, 
    mapDispatchToProps 
)(ModalCore);

const ShowAnimation = keyframes`
	  0% {
	    transform: scale(.7);
	  }
	  45% {
	    transform: scale(1.05);
	  }
	  80% {
	    transform: scale(.95);
	  }
	  100% {
	    transform: scale(1);
	  }
`
const Modal = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 7000;
    opacity: 0;
    pointer-events: none;
    .modalContent{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
    &.close {
		  display: none;
      opacity: 0;
      pointer-events: none;
    }
    &.open {
        opacity: 1;
        pointer-events: all;
        .modalContent{
            animation: ${ShowAnimation} 0.2s alternate forwards;
            .contentwrap{
                pointer-events: visible;
            }
        }
    }
`;
const ModalBackdrop = styled.div`
    position: relative;
    display: block;
    width:100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
`;