const cdn_path = 'https://cdn-webth.garenanow.com/webth/cdn/fconline/packfactory-v3';

const logo_diy = cdn_path +'/logo.png';
const bg_choose = cdn_path +'/background.png';
const machine = cdn_path +'/machine.png';
const btn_login = cdn_path +'/btn_login.png';
const btn_login_hover = cdn_path +'/btn_login_hover.png';
const btn_logout = cdn_path +'/btn_logout.png';
const btn_logout_hover = cdn_path +'/btn_logout_hover.png';
const board_manager = cdn_path +'/board_manager.png';
const btn_acc_history = cdn_path +'/btn_history.png';
const btn_acc_history_hover = cdn_path +'/btn_history_hover.png';
const btn_acc_rule = cdn_path +'/btn_rule.png';
const btn_acc_rule_hover = cdn_path +'/btn_rule_hover.png';
const select_class = cdn_path +'/select_class.png';
const select_class_active = cdn_path +'/select_class_active.png';
const select_top = cdn_path +'/select_top.png';
const select_top_active = cdn_path +'/select_top_active.png';
const select_upgrade = cdn_path +'/select_upgrade.png';
const select_upgrade_active = cdn_path +'/select_upgrade_active.png';
const controler = cdn_path +'/controler.png';
const controler_btn_confirm = cdn_path +'/controler_btn_confirm.png';
const controler_btn_confirm_hover = cdn_path +'/controler_btn_confirm_hover.png';
const controler_btn_confirmed = cdn_path +'/controler_btn_confirmed.png';
const box = cdn_path +'/box.png';
const s_top_free = cdn_path +'/s_top_free.png';
const s_upgrade_free = cdn_path +'/s_upgrade_free.png';
const popup_rule = cdn_path +'/popup_rule.png';
const popup_rule_box = cdn_path +'/popup_rule_box.png';
const btn_close = cdn_path +'/btn_close.png';
const popup_history = cdn_path +'/popup_history.png';
const popup_confirm = cdn_path +'/popup_confirm.png';
const btn_accept = cdn_path +'/btn_accept.png';
const btn_cancel = cdn_path +'/btn_cancel.png';
const popup_item = cdn_path +'/popup_item.png';
const btn_loadmore = cdn_path +'/btn_loadmore.png';
const btn_receive = cdn_path +'/btn_receive.png';
const logo_fifa = cdn_path +'/logo_game_2.png';


export const imgList = {
    logo_diy,
    bg_choose,
    machine,
    btn_login,
    btn_login_hover,
    btn_logout,
    btn_logout_hover,
    board_manager,
    btn_acc_history,
    btn_acc_history_hover,
    btn_acc_rule,
    btn_acc_rule_hover,
    select_class,
    select_class_active,
    select_top,
    select_top_active,
    select_upgrade,
    select_upgrade_active,
    controler,
    controler_btn_confirm,
    controler_btn_confirm_hover,
    controler_btn_confirmed,
    box,
    s_top_free,
    s_upgrade_free,
    popup_rule,
    popup_rule_box,
    btn_close,
    popup_history,
    popup_confirm,
    btn_accept,
    btn_cancel,
    popup_item,
    btn_loadmore,
    btn_receive,
    logo_fifa
}
