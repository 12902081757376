const initialState = {
  modal_open: '',
  modal_message_head: '',
  modal_title: '',
  modal_message: '',
  modal_message_color: "#000000",
  modal_currency: '',
  modal_amount: '',
  modal_reward: null,
  modal_redirect: '',
  modal_type: 1,
  modal_image: 0,
  modal_history: [],
  modal_history_page: 1,
  modal_history_canload: false,
  modal_team: 'blue',
  modal_random: 0,
  
  modal_history_game: [],
  modal_history_page_game: 1,
  modal_history_canload_game: false,
  select_item: { name: '', img: '', price: '' },
};

export const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_VALUE":
      if (Object.keys(initialState).indexOf(action.key) >= 0) {
        return {
          ...state,
          [action.key]: action.value
        };
      } else {
        return state;
      }
    case "SET_VALUES": return { ...state, ...action.value };
    default:
      return state;
    case "CLOSE":
      return Object.assign({}, state, {
        modal_open: '',
        select_item: '',
      });
    case "CONFIRM":
      return Object.assign({}, state, {
        modal_open: '',
      });
    case "SELECT_ITEM":
      return Object.assign({}, state, {
        select_item: action.value
      });
  }
};

export const setValue = (key, value) => {
  return ({
    type: "SET_VALUE",
    value,
    key
  })
};

export const setValues = (value) => {
  // console.log(value)
  return ({
    type: "SET_VALUES",
    value
  })
};

export const actCloseModal = () => {
  return ({
    type: "CLOSE",
  })
}

export const actConfirmModal = () => {
  return ({
    type: "CONFIRM",
  })
}

export const actSelectItem = (value) => {
  return ({
    type: "SELECT_ITEM",
    value
  })
}
