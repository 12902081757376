import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setInGame } from './redux';

const SetOut = props => {
  let { cookie_name } = props.inGame;
  let newInGameData = {
    ...props.inGame,
    status: false
  };
  delCookie(cookie_name);
  props.setInGame(newInGameData);

  return <Redirect to={props.to || '/'} />;
};

const mapStateToProps = state => ({ inGame: state.inGame });
const mapDispatchToProps = { setInGame };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetOut);

// function ====================================================================
const delCookie = cname => {
  document.cookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:01 UTC;';
};
//==============================================================================
