import consent_values from './../middleware/consent/src/reduxState';

const SET_LOGIN_URL = 'SET_LOGIN_URL';
const SET_LOGOUT_URL = 'SET_LOGOUT_URL';

const initialState = {
    ...consent_values,
    error: false,
    loading: false,
    hideLoader: false,
    loginUrl: "",
    logoutUrl: "",
    doLogout: false,
    userData: null,

    userInfo: {
        manager_name: "",
        lt: "",
        token: 0,
        mission: 0,
        team: null
    },
    link_video: "https://www.youtube.com/embed/sRJ6VylvE_k",
    selected: {
        name: "",
        image: "",
        class: 0,
        top: 0,
        grade: 0,
        match: 0,
        match_max: 0,
        match_daily: 0,
        status: false
    },

    select_class: 0,
    select_top: -1,
    select_upgrade: -1,
    api: false,
    showChoose: []
};

export const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_URL      : return { ...state, loginUrl: action.value };
    case SET_LOGOUT_URL     : return { ...state, logoutUrl: action.value };
    case "SET_VALUES"       : return { ...state, ...action.value };

    default:
      return state;
  }
};


export const setLoginUrl    = value => ({ type: SET_LOGIN_URL, value});
export const setLogoutUrl   = value => ({ type: SET_LOGOUT_URL, value});
export const setValues      = value => ({ type: 'SET_VALUES', value });
// export const actCloseModal  = value => ({type:"CLOSE",value});


