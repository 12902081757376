/*
    version 2.1.3 by pantapat.f@sea.com @ 19 sep 2023 
    update for old component 
*/
import React from "react";
import styled from "styled-components";

export default class Navbar extends React.Component {
  
  constructor(props){
    super(props);

    this.state = {
      autoHide:         props.autoHide          || false,
      backgroundActive: props.backgroundActive  || "",
      delayHide:        props.delayHide         || 3,
      fontColor:        props.fontColor         || "#ffffff",
      fontFamily:       props.fontFamily        || {name: "", list: []},
      fontSize:         props.fontSize          || "1rem",
      hoverFontColor:   props.hoverFontColor    || "#000000",
      list:             props.list              || [],
      logo:             props.logo              || {image:"",link:""},
      maxWidth:         props.maxWidth          || "1700px",

      showNavbar: "0%"
    };

    this.regexGetFileType = new RegExp("(?!.*\\.)(\\w*)","g");
    this.menuRef = React.createRef();
    this.tmo = null;

  };
  
  componentDidMount() {
    if(!!this.state.autoHide) {
      this.hideContent.bind(this)();
    }

    this.fetchData.bind(this)();
  }

  // function ================
  showContent() {
    if(!this.menuRef.current) return;

    if(!!this.tmo) clearTimeout(this.tmo);
    this.setState({showNavbar: "show" });
  };
  hideContent() {
    if(!this.menuRef.current) return;

    if(!!this.tmo) clearTimeout(this.tmo);
    this.tmo = setTimeout(()=>{
      this.setState({showNavbar: "hide" });
    },this.state.delayHide*1000);
  };
  generateFontfaceSrc(aryUrl) {
    let list = [];
    for(let i = 0; i< aryUrl.length; i++) {
      list.push("url("+aryUrl[i]+") format('"+aryUrl[i].match(this.regexGetFileType)[0]+"')")
    }
    return list.join(",");
  }
  async fetchData() {
    let data = {};
    let objUpdate = {...this.state}

    let url = "https://cdn-webth.garenanow.com/webth/cdn/fconline/navbar.json";
    let option = { 
        headers: { 'Accept': 'application/json'} 
    };
    let res = await fetch(url,option);

    if(res.status === 200) data = await res.json();
    
    objUpdate = {...this.state, ...data, ...this.props};
    
    this.setState(objUpdate);
    
  }
  // function ================

  render(){

    if(!!this.props.inGame && !!this.props.inGame.status) return null;

    let propsData = {
      fontFamilyName: this.state.fontFamily.name,
      fontFamilyUrl: this.generateFontfaceSrc(this.state.fontFamily.list),
      
      backgroundActive: this.state.backgroundActive,
      fontColor: this.state.fontColor, 
      fontSize: this.state.fontSize, 
      hoverFontColor: this.state.hoverFontColor,    
      maxWidth: this.state.fontFamily.maxWidth
    }
    
    let setting = !this.state.autoHide? {} : {
      onMouseEnter: this.showContent.bind(this),
      onMouseLeave: this.hideContent.bind(this),
    }

    return (
      <NavbarLayout className={"navbar "+ this.state.showNavbar} {...propsData} {...setting} ref={this.menuRef} >
        <div className="navbar__area">
          <div className="navbar__content">
            {!!this.state.logo.image &&
              <a className="navbar__logo" href={this.state.logo.link } target="_blank" rel="noreferrer" >
                <img src={this.state.logo.image} alt="game logo" />
              </a>
            }
            <div className="navbar__menu">
              {this.state.list.map((item, index) => (
                <a
                  className="navbar__button"
                  href={item.link || ""}
                  target="_blank"
                  rel='noreferrer'
                  key={index}
                >
                  {item.title || ""}
                </a>
              ))}
            </div>
          </div>
        </div>
      </NavbarLayout>
    )
  }
}

const NavbarLayout = styled.div`
  /* variable */
  --menu-move: 0%;

  @font-face {
      font-family: ${props=>props.fontFamilyName};
      src: ${props=>props.fontFamilyUrl};
      font-weight: 300;
      font-style: normal;
  }
  font-size: ${props=>props.fontSize};
  font-family: ${props=>props.fontFamilyName};
  position: sticky;
  width: 100%;
  height: 3em;
  z-index: 500;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &.show .navbar__area {
    transform: translateY(0);
  }
  &.hide .navbar__area {
    transform: translateY(-100%);
  }
  * {
    box-sizing: border-box;
  }
  .navbar {
    &__area {
      background-color: black;
      /* transform: translateY(var(--menu-move)); */
      transition: transform 0.3s ease-in-out;
      width: 100%;
      height: 100%;
    }
    &__content {
      width: 89%;
      max-width: ${props=>props.maxWidth};
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__logo {
      position: relative;
      height: 100%;
      img {
        height: 100%;
      }
    }
    &__menu { 
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      flex: 1;
      height: 100%;
    }
    &__button {
      // background image size: 251px x 47px
      cursor: pointer;
      text-decoration: none;
      color: ${props=>props.fontColor};
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: calc(2em  / 47 * 241);
      height: 100%;
      font-size: 1.5em;
      &:hover {
        color: ${props=>props.hoverFontColor};
        background-image: url(${props=>props.backgroundActive});
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
`;
