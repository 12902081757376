export function apiGetConsnet( 
	event_key,
  fnc_succ=()=>{}, 
  fnc_res=()=>{}, 
  fnc_err=()=>{}
) {
  let url = process.env.REACT_APP_CONSENT_HOST + process.env.REACT_APP_CONSENT_API;
  let parameter = { 
    method: 'GET', 
    headers: { 
			'Content-Type': 'application/json',
    } 
  };
  fetch(url,parameter).then(response => {
    if (response.status == 200) {
      response.json().then(data => {
        fnc_succ(data);
      });
    } else {
      fnc_res(response);
    }
  }, error => {
    fnc_err(error);
  });
}

export function apiPostConsnet(
	token,
	accept,
	event_key,
  fnc_succ=()=>{}, 
  fnc_res=()=>{}, 
  fnc_err=()=>{}
) {
	let url = process.env.REACT_APP_CONSENT_HOST + process.env.REACT_APP_CONSENT_API;
	let parameter = { 
    method: 'POST', 
    headers: { 
			'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
		body: JSON.stringify({accept, event_key})
  };
	fetch(url,parameter).then(response => {
    if (response.status == 200) {
      response.json().then(data => {
        fnc_succ(data);
      });
    } else {
      fnc_res(response);
    }
  }, error => {
    fnc_err(error);
  });
} 

// version 2 
export function apiConsentInfo( 
	event_key,
	token,
	lang,
  fnc_succ=()=>{}, 
  fnc_res=()=>{}, 
  fnc_err=()=>{}
) {
  let url = process.env.REACT_APP_CONSENT_HOST + process.env.REACT_APP_CONSENT_API_INFO;
  let parameter = { 
    method: 'POST', 
    headers: { 
			'Content-Type': 'application/json',
    },
		body: JSON.stringify({token, event_key, lang})
  };
  fetch(url,parameter).then(response => {
    if (response.status == 200) {
      response.json().then(data => {
        fnc_succ(data);
      });
    } else {
      fnc_res(response);
    }
  }, error => {
    fnc_err(error);
  });
}


export function apiConsentAccept(
	token,
	accept,
	event_key,
  fnc_succ=()=>{}, 
  fnc_res=()=>{}, 
  fnc_err=()=>{}
) {
	let url = process.env.REACT_APP_CONSENT_HOST + process.env.REACT_APP_CONSENT_API_ACCEPT;
	let parameter = { 
    method: 'POST', 
    headers: { 
			'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
		body: JSON.stringify({accept, event_key})
  };
	fetch(url,parameter).then(response => {
    if (response.status == 200) {
      response.json().then(data => {
        fnc_succ(data);
      });
    } else {
      fnc_res(response);
    }
  }, error => {
    fnc_err(error);
  });
} 