import React,{useEffect,useState} from 'react';
import Styled,{ keyframes } from 'styled-components';
import { imgList } from './../constants/ImagesList';
import { cardReward, cardTopReward, cardUpgradeReward } from './../constants/Reward';

export default (props) => {
    const [ani, setAni] = useState(false);
    useEffect(() => {
        if(props.home){
            setAni(true);
            setTimeout(() => {
                setAni(false);
            }, 300);
        }
    }, [props.sclass]);
    return (
        <Card home={props.home} animation={ani}>
            <img className="cardreward" src={cardReward[props.sclass].image} alt=''/>
            <img className="cardupgrade" src={props.supdate == -1 ? imgList.s_upgrade_free : cardUpgradeReward[props.supdate].image} alt=''/>
            <img className="cardtop" src={props.stop == -1 ? imgList.s_top_free : cardTopReward[props.stop].image} alt=''/>
        </Card>
    )
}

const aniShow = keyframes`
    0%  {opacity: 0;transform: scale(1);}
    80%  {opacity: 0;transform: scale(2);}
    100% {opacity: 1;transform: scale(1);}
`;
const Card = Styled.div`
    width: 80%;
    position: relative;
    ${props=>props.home
    ?
        `margin-left: 3%;`
    :
        `margin: 0 auto;`
    }
    .cardreward{
        width: 100%;
        animation: ${props=>props.animation?aniShow:""} .3s forwards linear;
        display: block;
    }
    .cardupgrade{
        width: 28%;
        position: absolute;
        top: 30%;
        right: 4%;
        display: block;
    }
    .cardtop{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 70%;
        display: block;
    }
`;
