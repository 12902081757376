import React from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';
import { setValues } from '../store/redux';
import { setValues as setModalValues } from '../features/modal/redux';

const Account = props => {
    return (
        <Content className="acc">
            {props.userData
            ?
                <div className="acc__logout" onClick={()=>props.handleLogout()}>
                    <img src={imgList.btn_logout} alt=''/>
                    <img src={imgList.btn_logout_hover} alt=''/>
                </div>
            :
                <a href={props.loginUrl} className="acc__logout">
                    <img src={imgList.btn_login} alt=''/>
                    <img src={imgList.btn_login_hover} alt=''/>
                </a>
            }
            <div className="acc__inner">
                <div className="acc__name">
                    <span>สวัสดี </span>{props.userInfo.manager_name}
                </div>
                <div className="acc__btn-group">
                    <div className="acc__btn" onClick={()=>props.handleModalOpen('history')}>
                        <img src={imgList.btn_acc_history} alt=''/>
                        <img src={imgList.btn_acc_history_hover} alt=''/>
                    </div>
                    <div className="acc__btn" onClick={()=>props.handleModalOpen('rule')}>
                        <img src={imgList.btn_acc_rule} alt=''/>
                        <img src={imgList.btn_acc_rule_hover} alt=''/>
                    </div>
                </div>
            </div>
        </Content>
    )
}
const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});
const mapDispatchToProps = { setValues, setModalValues };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Account);

const Content = styled.div`
    position: relative;
    width: 100%;
    .acc{
        &__logout{
            // position: absolute;
            // top: -6%;
            // left: 50%;
            // transform: translate(-50%, 0);
            width: 40%;
            cursor: pointer;
            transition: opacity .2s ease-in-out;
            margin: 3% auto;
            position: relative;
            display: block;
            >img{
                width: 100%;
                display: block;
                &:nth-child(2){
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            &:hover{
                >img{
                    &:nth-child(1){
                        opacity: 0;
                    }
                    &:nth-child(2){
                        opacity: 1;
                    }
                }
            }
        }
        &__name{
            font-family: 'PSLSimilanyaExtraPro';
            text-align: center;
            color: #fd0afa;
            font-size: 2vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            >span{
                color: #fff;
            }
        }
        &__inner{
            background: center no-repeat url(${imgList.board_manager});
            background-size: contain;
            padding: 4% 15%;
        }
        &__used{
            position: relative;
            margin-bottom: 2%;
            >img{
                width: 100%;
                display: block;
            }
            >div{
                position: absolute;
                bottom: -25%;
                left: 35%;
                color: #d75df6;
                width: 47%;
                font-size: 5.5vw;
                text-align: center;
            }
            &--mission{
                >div{
                    bottom: -20%;
                    color: #33e3f3;
                }
            }
        }
        &__btn-group{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__btn{
            position: relative;
            display: block;
            margin: 2% 4%;
            cursor: pointer;
            >img{
                width: 100%;
                display: block;
                &:nth-child(2){
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            &:hover{
                >img{
                    &:nth-child(1){
                        opacity: 0;
                    }
                    &:nth-child(2){
                        opacity: 1;
                    }
                }
            }
        }
    }
`;