import React,{useEffect,useState} from 'react';
import styled,{keyframes} from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';
import { vdoList } from './../constants/VideoList';
import VideoBackground from './../features/videobackground';
import Navbar from '../common/Navbar';
import Machine from './../features/machine';
import Account from './../features/account';
import Controler from './../features/controler';
import BlockDisplay from './../features/blockdisplay';
import Footer from '../common/Footer';
import { InGameMenu } from './../features/InGame';
import { setValues } from '../store/redux';
import { setValues as setModalValues } from '../features/modal/redux';
import {checkJwtCookie,getCookie,setCookie} from './../constants/Cookie';

import {
    LoadingModal,
    ConfirmChooseModal, 
    RuleModal,
    HistoryModal,
    MessageModal,
    ItemModal
} from '../features/modal';

const Home = props => {
    const [loadHistory, setLoadHistory] = useState(true);

    useEffect(() => {
        if(props.userData && props.userInfo.manager_name === ''){
            apiGetInfo();
        }
    }, [props.userData]);

    const apiGetInfo = () => {
        if(!checkJwtCookie()){
            props.setModalValues({
                modal_open: 'message',
                modal_message: 'กรุณาเข้าสู่ระบบ'
            })
            return;
        }
        if (props.loading) return;
        props.setValues({
            loading: true,
        })
        props.setModalValues({
            modal_open: 'loading'
        })
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_MAIN, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userData.jwtToken
            },
            body: JSON.stringify({
                service: 'getInfo'
            })
        }).then(response => response.json()).then(response => {
            if (!response.status) {
                throw new Error(response.message)
            }
            props.setValues({
                loading: false,
                api: true,
                ...response.data
            })
            props.setModalValues({
                modal_open: ''
            })
        }).catch(error => {
            console.log('API Error : ', error)
            props.setValues({
                loading: false
            })
            props.setModalValues({
                modal_open: 'message',
                modal_message: error.message || 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
            })
        })
    }
    const apiSelectItem = () =>{
        let classApi = props.select_class+1;
        let topApi = props.select_top+1;
        let gradeApi = props.select_upgrade+1;

        if(!checkJwtCookie() || props.userInfo.manager_name===''){
            props.setModalValues({
                modal_open: 'message',
                modal_message: 'กรุณาเข้าสู่ระบบ'
            })
            return;
        }
        if (props.loading) return;
        props.setValues({
            loading: true,
        })
        props.setModalValues({
            modal_open: 'loading'
        })
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_MAIN, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userData.jwtToken
            },
            body: JSON.stringify({
                service: 'selectItem',
                class: classApi,
                top: topApi,
                grade: gradeApi
            })
        }).then(response => response.json()).then(response => {
            if (!response.status) {
                throw new Error(response.message)
            }
            props.setValues({
                loading: false,
                userInfo: {
                    ...props.userInfo,
                    ...response.data.userInfo
                },
                selected: response.data.selected,
                select_class: 0,
                select_top: -1,
                select_upgrade: -1,
                showChoose: []
            })
            props.setModalValues({
                modal_open: ''
            })
        }).catch(error => {
            console.log('API Error : ', error)
            props.setValues({
                loading: false
            })
            props.setModalValues({
                modal_open: 'message',
                modal_message: error.message || 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
            })
        })
    }
    const apiClaim = () =>{
        if(!checkJwtCookie() || props.userInfo.manager_name===''){
            props.setModalValues({
                modal_open: 'message',
                modal_message: 'กรุณาเข้าสู่ระบบ'
            })
            return;
        }
        if (props.loading) return;
        props.setValues({
            loading: true,
        })
        props.setModalValues({
            modal_open: 'loading'
        })
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_MAIN, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userData.jwtToken
            },
            body: JSON.stringify({
                service: 'claim'
            })
        }).then(response => response.json()).then(response => {
            if (!response.status) {
                throw new Error(response.message)
            }
            props.setValues({
                loading: false,
                userInfo: {
                    ...props.userInfo,
                    ...response.data.userInfo
                },
                selected: response.data.selected,
                select_class: 0,
                select_top: -1,
                select_upgrade: -1,
                showChoose: []
            })
            props.setModalValues({
                modal_open: 'item',
                modal_reward: response.data.items,
            })
        }).catch(error => {
            console.log('API Error : ', error)
            props.setValues({
                loading: false
            })
            props.setModalValues({
                modal_open: 'message',
                modal_message: error.message || 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
            })
        })
    }
    const apiGetHistory = () =>{
        if(!checkJwtCookie() || props.userInfo.manager_name===''){
            props.setModalValues({
                modal_open: 'message',
                modal_message: 'กรุณาเข้าสู่ระบบ'
            })
            return;
        }
        if (props.loading) return;
        if(loadHistory){
            props.setValues({
                loading: true,
            })
            props.setModalValues({
                modal_open: 'loading'
            })
            fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_MAIN, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.userData.jwtToken
                },
                body: JSON.stringify({
                    service: 'getHistory',
                    page: '1'
                })
            }).then(response => response.json()).then(response => {
                if (!response.status) {
                    throw new Error(response.message)
                }
                props.setModalValues({
                    modal_open: 'history',
                    modal_history: response.data.history,
                    modal_history_page: response.data.page,
                    modal_history_canload: response.data.canLoadMore
                })
                props.setValues({
                    loading: false
                })
                setLoadHistory(false);
            }).catch(error => {
                console.log('API Error : ', error)
                props.setValues({
                    loading: false
                })
                props.setModalValues({
                    modal_open: 'message',
                    modal_message: error.message || 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
                })
            })
        }
        else{
            props.setModalValues({
                modal_open: 'history'
            })
        }
    }
    const apiLoadmoreHistory = (page) => {
        let pageApi = page
        if(!checkJwtCookie() || props.userInfo.manager_name===''){
            props.setModalValues({
                modal_open: 'message',
                modal_message: 'กรุณาเข้าสู่ระบบ'
            })
            return;
        }
        if (props.loading) return;
        props.setValues({
            loading: true,
        })
        props.setModalValues({
            modal_open: 'loading'
        })
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_MAIN, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userData.jwtToken
            },
            body: JSON.stringify({
                service: 'getHistory',
                page: pageApi
            })
        }).then(response => response.json()).then(response => {
            if (!response.status) {
                throw new Error(response.message)
            }
            props.setModalValues({
                modal_open: 'history',
                modal_history: [
                    ...props.modal_history,
                    ...response.data.history
                ],
                modal_history_page: response.data.page,
                modal_history_canload: response.data.canLoadMore
            })
            props.setValues({
                loading: false
            })
            setLoadHistory(false);
        }).catch(error => {
            console.log('API Error : ', error)
            props.setValues({
                loading: false
            })
            props.setModalValues({
                modal_open: 'message',
                modal_message: error.message || 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
            })
        })
    }
    const handleModalOpen = (type) =>{
        if(!checkJwtCookie() || props.userInfo.manager_name===''){
            props.setModalValues({
                modal_open: 'message',
                modal_message: 'กรุณาเข้าสู่ระบบ'
            })
            return;
        }
        if(type==='history'){
            apiGetHistory();
        }
        else{
            props.setValues({ modal_open: type})
        }
    }
    const handleLogout = () =>{
        props.setValues({ doLogout: true })
    }
    return (
        <Main className="main">
            <BlockDisplay show='portrait'/>

            <Navbar inGame={props.inGame} fontSize="1.13rem" />

            <div className='main__game'>
                <VideoBackground
                    detail={[
                        {webm: vdoList.bg_1,poster: imgList.bg_1},
                        {webm: vdoList.bg_2,poster: imgList.bg_2}
                    ]}
                />
                <InGameMenu landingtoken={props.userInfo.lt}/>    
                <Play className="play" team={props.userInfo.team}>
                    <img src={imgList.logo_fifa} alt='' className="play__logo-fifa"/>
                    
                    <div className="play__top">
                        <img src={imgList.logo_diy} alt='' className="play__logo"/>
                        <div className="play__machine">
                            <Machine
                                apiClaim={()=>apiClaim()}
                                handleModalOpen={(type)=>handleModalOpen(type)}
                            />
                            <Controler />
                        </div>
                    </div>
                    <div className="play__account">
                        <Account
                            handleModalOpen={(type)=>handleModalOpen(type)}
                            handleLogout={()=>handleLogout()}
                        />
                    </div>
                </Play> 
            </div>
            
            <Footer inGame={props.inGame} fontSize="1.13rem" />
            
            <LoadingModal/>
            <RuleModal/>
            <HistoryModal
                apiLoadmoreHistory={(page)=>apiLoadmoreHistory(page)}
            />
            <MessageModal/>
            <ConfirmChooseModal
                apiSelectItem={()=>apiSelectItem()}
            />
            <ItemModal/>
        </Main>
    )
}

const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal,
    inGame: state.inGame
});

const mapDispatchToProps = { setValues, setModalValues };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);

const Main = styled.div`
    width: 100%;
    .main {
        &__game {
            width: 100%;
            padding-top: calc(100%*1620/1920);
            background: url(${imgList.bg_choose}) no-repeat center;
            background-size: cover;
            position: relative;
        }
    }
`;
const Play = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
    .play{
        &__logo-fifa{
            position: absolute;
            width: 5%;
            display: block;
            top: 7%;
            left: 2%;
        }
        &__top{
            width: 100%;
            position: absolute;
            bottom: 5.2%;
            left: 0;
        }
        &__machine{
            width: 73.5%;
            margin-top: 1%;
        }
        &__account{
            width: 27%;
            position: absolute;
            top: 23%;
            right: 18%;
            z-index: 10;
        }
        &__logo{
            width: 48%;
            margin: 0 auto;
            opacity: 0;
            display: block;
            @media only screen and (max-width: 1024px) {
                opacity: 1;
            }
        }
        &__weekly{
            width: 46.4%;
            margin-left: 18.6%;
            margin-top: 2%;
        }
    }
`;

