import React from 'react';
import styled from 'styled-components';
import  ModalCore from './ModalCore.js';
import { connect } from "react-redux";
import {setValues} from './redux';
import { imgList } from './../../constants/ImagesList';

const RuleModal = (props) => {
	return (
		<ModalCore name="rule" outSideClick={true}>
			<ModalContent>
                <img src={imgList.popup_rule} alt='' />
                <img className="bgbox" src={imgList.popup_rule_box} alt=''/>
                <BtnClose onClick={()=>props.setValues({modal_open: ''})}>
					<img src={imgList.btn_close} alt=''/>
				</BtnClose>
			</ModalContent>
		</ModalCore>
	)
}

const mapStateToProps = state => ({
	...state.Main,
    ...state.Modal
});

const mapDispatchToProps = {setValues};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RuleModal);

const ModalContent = styled.div`
    position: relative;
    width: 45%;
    pointer-events: visible;
    @media only screen and (max-width: 850px) {
        width: 35%;
    }
    .bgbox{
        width: 32vmin;
        position: absolute;
        bottom: -10vmin;
        right: -14vmin;
    }
`;
const BtnClose = styled.div`
    position: absolute;
    top: -10.5vmin;
    right: -.5vmin;
    width: 10vmin;
    cursor: pointer;
    transition: opacity .2s ease-in-out;
    &:hover{
        opacity: .8;
    }
    >img{
        width: 100%;
        display: block;
    }
`;
