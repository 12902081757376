import React from 'react';
// import { combineReducers, createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
// Page Components
import Home from './pages/Home';
import InGame from './pages/InGame';
import { InGameCheckCookie } from './features/InGame';
import configStore from './store/ConfigStore';
// Route Middleware
import OauthMiddleware from './middleware/OauthMiddleware';
import './styles/index.scss';
import Consent from './middleware/consent'
const { store, persistor } = configStore();

const eventPath = process.env.REACT_APP_EVENT_PATH;

const Routes = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <>
                    <Route path={eventPath} component={Consent} />
                    <Route path={eventPath} component={OauthMiddleware}/>
                    <Route path={eventPath} exact component={Home}/>
                    <Route path={'/'} component={InGameCheckCookie} />
                    <Route path={eventPath + '/ingame'} exact component={InGame}/>
                </>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

export default Routes;
